import { TableColumnProps } from 'components/table/table-component/table-component';

export const assetsTableStructure: TableColumnProps[] = [
  {
    id: 'name',
    title: 'Repository',
    position: 'left',
    withSort: true,
    noFilters: true,
    withSearch: true,
  },
  {
    id: 'repo_owner',
    title: 'Owner',
    position: 'left',
  },
  {
    id: 'highest_severity',
    title: 'Risk Rating',
    position: 'left',
    withSort: true,
  },
  {
    id: 'vulnerability_count',
    title: 'Total Vulns',
    position: 'left',
    withSort: true,
    noFilters: true,
  },
  {
    id: 'oss_count',
    title: '# SCA',
    position: 'left',
    withSort: true,
    noFilters: true,
  },
  {
    id: 'secret_count',
    title: '# Secrets',
    position: 'left',
    withSort: true,
    noFilters: true,
  },
  {
    id: 'sast_count',
    title: '# SAST',
    position: 'left',
    withSort: true,
    noFilters: true,
  },
  {
    id: 'iac_count',
    title: '# IaC',
    position: 'left',
    withSort: true,
    noFilters: true,
  },
  // {
  //   id: 'vulnerability_counts',
  //   title: 'Vuln. Counts',
  //   position: 'left',
  // },
  {
    id: 'permission',
    title: 'Permissions Granted',
    position: 'left',
    withSort: true,
    noFilters: true,
  },
  {
    id: 'last_activity',
    title: 'Last Activity',
    position: 'left',
    isDate: true,
    withSort: true,
    noFilters: true,
  },
];
