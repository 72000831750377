import { Filter } from 'store/modules/filters/filters.reducer';

export interface DownloadFileProps {
  total?: number;
  sortBy?: string;
  tableFilters?: Filter;
  getAccessTokenSilently: () => Promise<string>;
  keycloak: any;
  apiUrl: string;
  fileName: string;
  searchBy?: any[];
  isCsv?: boolean;
  searchQuery?: string;
  startDate?: Date | string | null;
  endDate?: Date | string | null;
  errorToastMessage: () => void;
  successToastMessage: () => void;
  regenerationToastMessage: () => void;
  source?: string;
}

export const requestReportGeneration = async ({
  total,
  apiUrl,
  sortBy,
  tableFilters,
  getAccessTokenSilently,
  keycloak,
  fileName,
  isCsv,
  searchBy,
  searchQuery,
  startDate,
  endDate,
  errorToastMessage,
  successToastMessage,
  regenerationToastMessage,
  source,
}: DownloadFileProps): Promise<void | {
  errorTitle: string;
  errorSubtitle: string;
}> => {
  const authToken = keycloak.token;

  if (!authToken) {
    return {
      errorTitle: 'Unauthorized!',
      errorSubtitle: 'Please login and try again',
    };
  }

  const headers: { authorization: string; accept: string; csvexport?: string } =
    {
      authorization: `Bearer ${authToken}`,
      accept: 'application/pdf',
      csvexport: 'true',
    };

  if (isCsv) {
    headers.accept = 'application/json';
    headers.csvexport = 'true';
  }

  let queryParams = '';

  if (total) {
    queryParams = `items_page=100`;
  }

  if (sortBy) {
    queryParams = queryParams + `&sort_by=${sortBy}`;
  }

  if (source) {
    queryParams = queryParams + `&source=${source}`;
  }

  if (searchQuery) {
    queryParams = queryParams + `&query=${searchQuery}`;
  }

  if (startDate && endDate) {
    queryParams = queryParams + `&start_date=${startDate}&end_date=${endDate}`;
  }

  if (tableFilters) {
    Object.keys(tableFilters).forEach((key) => {
      if (
        tableFilters?.[key] !== undefined &&
        key !== 'filterTitles' &&
        key !== 'sorting_order' &&
        key !== 'sorting_field' &&
        key !== 'searchChips'
      ) {
        queryParams = queryParams + `&${key}=${tableFilters?.[key]}`;
      }
    });
  }

  if (searchBy) {
    searchBy.forEach((searchByObject) => {
      queryParams =
        queryParams + `&${searchByObject.id}=${searchByObject.query}`;
    });
  }
  fetch(`${process.env.REACT_APP_BASE_URL}${apiUrl}?${queryParams}`, {
    headers: { ...headers },
  })
    .then((res) => {
      if (res.ok) {
        successToastMessage();
      }
      if (res.status === 429) {
        regenerationToastMessage();
      }
    })
    .catch(() => errorToastMessage());
};

export const downloadFileUtil = (blob: Blob, filename: string): void => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};
