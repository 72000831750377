import { TableColumnProps } from 'components/table/table-component/table-component';

export const orgVulnerabilityReportTableStructure: TableColumnProps[] = [
  {
    id: 'name',
    title: 'Identity Name',
    position: 'left',
    noFilters: true,
    withSearch: true,
    withSort: true,
  },
  {
    id: 'job_title',
    title: 'Job Title',
    position: 'left',
    noFilters: true,
  },
  {
    id: 'oss',
    title: '# SCA',
    position: 'left',
    noFilters: true,
  },
  {
    id: 'secret',
    title: '# Secrets',
    position: 'left',
    noFilters: true,
  },
  {
    id: 'sast',
    title: '# SAST',
    position: 'left',
    noFilters: true,
  },
  {
    id: 'dast',
    title: '# DAST',
    position: 'left',
    noFilters: true,
  },
  {
    id: 'iac',
    title: '# IaC',
    position: 'left',
    noFilters: true,
  },
];
